<template>
  <section class="offers__wrapper oblique oblique_1">
    <div class="container">
      <div class="offers__block">
        <AttentionBlock
          :title="
            owners
              ? $getTranslate(offersBlock.title_owners)
              : $getTranslate(offersBlock.title)
          "
          :text="$getTranslate(offersBlock.text)"
          class="attention-block_simple offers__block-txt"
        />

        <div v-if="owners" class="offers offers_owners">
          <a
            href="https://cabinet.costaction.com/auth/register"
            class="offers__item"
          >
            <inline-svg
              :src="require('@/assets/img/logo-turbobit.svg')"
              class="offers__item-logo"
            />
            <p v-html="$getTranslate('offers_owners_1')" />
            <p v-html="$getTranslate('offers_owners_2')" />
          </a>

          <a
            href="https://cabinet.costaction.com/auth/register"
            class="offers__item"
          >
            <inline-svg
              :src="require('@/assets/img/logo-hitfile.svg')"
              class="offers__item-logo"
            />

            <p v-html="$getTranslate('offers_owners_3')" />
            <p v-html="$getTranslate('offers_owners_4')" />
          </a>
        </div>

        <div v-else class="offers">
          <router-link :to="{ name: 'partners' }" class="offers__item">
            <inline-svg
              :src="require('@/assets/img/logo-turbobit.svg')"
              class="offers__item-logo"
            />
            <p>{{ $getTranslate('offers_pps') }}</p>
            <p>{{ $getTranslate('offers_ppd') }}</p>
            <p>{{ $getTranslate('offers_mixed') }}</p>
            <p>{{ $getTranslate('offers_pay') }}</p>
          </router-link>

          <router-link :to="{ name: 'partners' }" class="offers__item">
            <inline-svg
              :src="require('@/assets/img/logo-hitfile.svg')"
              class="offers__item-logo"
            />

            <p>{{ $getTranslate('offers_pps') }}</p>
            <p>{{ $getTranslate('offers_pay') }}</p>
          </router-link>

          <router-link
            :to="{ name: 'partners', hash: '#referal' }"
            class="offers__item"
          >
            <inline-svg
              :src="require('@/assets/img/logo-costaction.svg')"
              class="offers__item-logo"
            />

            <p>{{ $getTranslate('referal') }}</p>
          </router-link>
        </div>
      </div>

      <button class="btn btn_red offers__btn">
        {{ $getTranslate('start_earning') }}
        <img src="@/assets/img/icons/arrow-btn.svg" class="btn__icon" alt="" />
      </button>
    </div>
  </section>
</template>

<script>
export default {
  name: 'OffersBlock',

  props: {
    owners: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      offersBlock: {
        title: 'offers_block_title',
        title_owners: 'offers_block_title-owners',
        text: 'offers_block_text'
      }
    }
  }
}
</script>

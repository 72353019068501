import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  // redirects
  { path: '/contacts', redirect: { name: 'advertisers' } },
  { path: '/partner', redirect: { name: 'partners' } },
  { path: '/aboutus', redirect: { name: 'advertisers' } },
  {
    path: '/reg/form',
    beforeEnter() {
      window.location.replace("https://cabinet.costaction.com/auth/register")
    }
  },
  {
    path: '/login',
    beforeEnter() {
      window.location.replace("https://cabinet.costaction.com/auth/login")
    }
  },

  {
    path: '/',
    name: 'index',
    component: Home
  },
  {
    path: '/partner',
    component: Home
  },
  {
    path: '/partner/:user_id',
    name: 'ref-link',
    component: Home
  },
  {
    path: '/advertisers',
    name: 'advertisers',
    component: () => import('../views/Advertisers.vue')
  },
  {
    path: '/owners',
    name: 'owners',
    component: () => import('../views/Owners.vue')
  },
  {
    path: '/partners',
    name: 'partners',
    component: () => import('../views/Partners.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

export default router

<template>
  <div class="how-block__item">
    <div class="how-block__item-img">
      <img
        v-if="item.img"
        :src="require('@/assets/img/icons/' + item.img)"
        loading="lazy"
        alt=""
      />
    </div>

    <div class="how-block__item-inner">
      <div
        v-if="item.title"
        class="how-block__item-title"
        v-html="$getTranslate(item.title)"
      />
      <div
        v-if="item.text"
        class="how-block__item-text"
        v-html="$getTranslate(item.text)"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'HowBlockItem',

  props: {
    item: {
      type: Object,
      default: null,
      required: true
    }
  }
}
</script>

<template>
  <div id="app" :class="{ 'rtl-dir': direction === 'rtl' }" :dir="direction">
    <div :class="'wrapper_' + $route.name" class="wrapper">
      <PageHeader />
      <main>
        <router-view />
      </main>
      <Contacts />
      <PageFooter />
      <div class="overlay-bg" />
    </div>
  </div>
</template>

<script>
import PageHeader from '@/components/PageHeader.vue'
import PageFooter from '@/components/PageFooter.vue'
import Contacts from '@/components/Contacts.vue'

export default {
  name: 'App',
  components: {
    PageHeader,
    PageFooter,
    Contacts
  },
  computed: {
    lang() {
      return this.$store.state.lang
    },

    direction() {
      if (this.lang === 'ar') {
        return 'rtl'
      }
      return 'auto'
    }
  },

  metaInfo() {
    return {
      title: this.$getTranslate('meta_main_title'),
      meta: [
        {
          name: 'description',
          content: this.$getTranslate('meta_main_description')
        },
        {
          property: 'og:title',
          content: this.$getTranslate('meta_main_title')
        },
        { property: 'og:site_name', content: 'Costaction' },
        {
          property: 'og:image',
          content: window.location.href + require('@/assets/img/bg/bg-1.png')
        },
        { property: 'og:type', content: 'website' },
        { name: 'robots', content: 'index,follow' }
      ]
    }
  },

  watch: {
    $route: function() {
      if (this.$router.currentRoute['hash']) {
        var element = this.$router.currentRoute['hash'].substr(1)
        setTimeout(() => {
          if (document.getElementById(element)) {
            var top = document.getElementById(element).offsetTop
            window.scrollTo({
              top: top,
              behavior: 'smooth'
            })
          }
        }, 100)
      }
    }
  },

  mounted() {
    if (localStorage.lang) {
      this.$store.dispatch('setLang', localStorage.lang)
    }

    this.setReferralCookies()
  },

  methods: {
    setReferralCookies() {
      const urlSearchParams = new URLSearchParams(window.location.search);
      const queryParams = Object.fromEntries(urlSearchParams.entries())

      let refId = queryParams.PHPSESUD

      if (!refId) {
        if (this.$route.name !== 'ref-link') {
          return
        }

        refId = this.$route.params.user_id

        if (!refId) {
          return
        }
      }

      let expire = 3600 * 24 * 365
      let domain = location.host.split('.').slice(-2).join('.').split(':')[0]

      this.$cookies.set('refuid', refId, expire, null, domain)
    }
  }
}
</script>

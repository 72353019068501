<template>
  <div
    :class="{ dark: $route.name === 'advertisers' }"
    class="contacts__wrapper"
  >
    <div class="container">
      <h2 class="page-title page-title_1">{{ $getTranslate('contacts') }}</h2>
      <div class="contacts">
        <ul
          v-for="(item, i) in contacts"
          class="contacts__list"
          :key="'contacts__list_' + i"
        >
          <li class="contacts__list-country">
            <inline-svg
              :src="require('@/assets/img/icons/flag-' + item.country + '.svg')"
            />
          </li>
          <li v-for="(link, j) in item.links" :key="'contacts__link' + j">
            <a
              :href="link.path"
              target="_blank"
              rel="noopener noreferrer"
              class="contacts__link"
            >
              <inline-svg
                :src="require('@/assets/img/icons/' + link.icon + '.svg')"
                class="contacts__link-icon"
              />
              <div v-html="link.text" />
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Contacts',
  data() {
    return {
      contacts: [
        {
          country: 'usa',
          links: [
            {
              path: 'mailto:partners@costaction.com',
              text: 'partners@costaction.com',
              icon: 'mail'
            },
            {
              path: 'tg://resolve?domain=costaction',
              text: '@costaction',
              icon: 'telegram'
            },
            {
              path: 'skype:wayupload.com',
              text: 'wayupload.com',
              icon: 'skype'
            },
            {
              path: 'icq:664461805',
              text: '664461805',
              icon: 'icq'
            }
          ]
        },

        {
          country: 'ru',
          links: [
            {
              path: 'mailto:partners@costaction.com',
              text: 'partners@costaction.com',
              icon: 'mail'
            },
            {
              path: 'tg://resolve?domain=costaction',
              text: '@costaction',
              icon: 'telegram'
            },
            {
              path: 'skype:wayupload.com',
              text: 'wayupload.com',
              icon: 'skype'
            }
          ]
        },

        {
          country: 'tr',
          links: [
            {
              path: 'mailto:ilker@costaction.com',
              text: 'ilker@costaction.com',
              icon: 'mail'
            },
            {
              path: 'tg://resolve?domain=IlkerCostaction',
              text: '@IlkerCostaction',
              icon: 'telegram'
            },
            {
              path: 'skype:live:.cid.45077ccf9f18a52f',
              text: 'live:.cid.45077ccf9f18a52f (Ilker&nbsp;Costaction)',
              icon: 'skype'
            }
          ]
        },

        {
          country: 'ar',
          links: [
            {
              path: 'mailto:ahmed@costaction.com',
              text: 'ahmed@costaction.com',
              icon: 'mail'
            },
            {
              path: 'tg://resolve?domain=ahmedcostaction',
              text: '@ahmedcostaction',
              icon: 'telegram'
            }
          ]
        }
      ]
    }
  }
}
</script>

<template>
  <div class="header__wrapper">
    <div class="container">
      <header class="header">
        <button
          :class="{ close: openMenu }"
          class="header__menu-btn"
          @click.prevent="toggleMenu"
        >
          <span /><span /><span />
        </button>

        <router-link to="/" class="header__logo">
          <img src="@/assets/img/logo.svg" alt="CostAction" />
        </router-link>

        <nav :class="{ show: openMenu }" class="header__nav">
          <ul>
            <li @click="hideMenu">
              <router-link :to="{ name: 'partners' }" class="header__link">
                {{ $getTranslate('to_partners') }}
              </router-link>
            </li>
            <li @click="hideMenu">
              <router-link :to="{ name: 'owners' }" class="header__link">
                {{ $getTranslate('to_owners') }}
              </router-link>
            </li>
            <li @click="hideMenu">
              <router-link :to="{ name: 'advertisers' }" class="header__link">
                {{ $getTranslate('to_advertisers') }}
              </router-link>
            </li>
          </ul>
        </nav>

        <div class="header__login-block" v-click-outside="hideLangs">
          <div class="lang">
            <button class="lang__btn" @click.prevent="toggleLangs">
              <inline-svg
                :src="require('@/assets/img/icons/flag-' + lang + '.svg')"
              />
              <inline-svg
                :class="{ down: langDropShow }"
                class="lang__icon-arrow"
                :src="require('@/assets/img/icons/up.svg')"
              />
            </button>

            <ul :class="{ show: langDropShow }" class="lang__list">
              <li v-for="(item, i) in langs" :key="'lang_' + i">
                <button
                  :class="{ active: item === lang }"
                  class="lang__btn"
                  @click.prevent="changeLang(item)"
                >
                  <inline-svg
                    :src="require('@/assets/img/icons/flag-' + item + '.svg')"
                  />
                  <inline-svg
                    class="lang__icon-check"
                    :src="require('@/assets/img/icons/check.svg')"
                  />
                </button>
              </li>
            </ul>
          </div>
          <a
            href="https://cabinet.costaction.com/auth/login"
            class="header__login-btn"
          >
            {{ $getTranslate('login_text') }}
          </a>
          <a
            href="https://cabinet.costaction.com/auth/register"
            class="btn btn_header btn_blue"
          >
            <div class="btn_header-hidden btn_header-text">
              {{ $getTranslate('login_text') }} /&nbsp;
            </div>
            <div class="btn_header-text">{{ $getTranslate('reg_text') }}</div>
            <img
              src="@/assets/img/icons/user.svg"
              alt=""
              loading="lazy"
              class="btn__icon"
            />
          </a>
        </div>
      </header>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageHeader',

  data() {
    return {
      langDropShow: false,
      langs: ['ru', 'en', 'ar', 'de', 'fr', 'tr', 'es', 'pt'],
      openMenu: false
    }
  },

  computed: {
    lang() {
      return this.$store.state.lang
    }
  },

  methods: {
    toggleLangs() {
      this.langDropShow = !this.langDropShow
      this.hideMenu()
    },

    hideLangs() {
      this.langDropShow = false
    },

    changeLang(item) {
      this.$store.dispatch('setLang', item)
      this.hideLangs()
    },

    toggleMenu() {
      this.openMenu = !this.openMenu
      document.getElementsByTagName('body')[0].classList.toggle('overflow')
    },

    hideMenu() {
      this.openMenu = false
      document.getElementsByTagName('body')[0].classList.remove('overflow')
    }
  }
}
</script>

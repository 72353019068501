import Vue from 'vue'
import Vuex from 'vuex'
import ru from './langs/ru.json'
import en from './langs/en.json'
import tr from './langs/tr.json'
import fr from './langs/fr.json'
import de from './langs/de.json'
import ar from './langs/ar.json'
import es from './langs/es.json'
import pt from './langs/pt.json'

Vue.use(Vuex)

function initialState() {
  let userLang = 'en'
  let browserLang = (navigator.language || navigator.userLanguage).substring(
    0,
    2
  )
  const ruList = [
    'be',
    'uk',
    'kk',
    'ky',
    'tg',
    'mn',
    'md',
    'ka',
    'hy',
    'az',
    'tk'
  ]
  // const langList = ['ru', 'en', 'tr', 'fr', 'de', 'ar']
  const langList = ['ru', 'en', 'ar', 'de', 'fr', 'tr', 'es', 'pt']
  langList.includes(browserLang)
  if (langList.includes(browserLang)) {
    userLang = browserLang
  } else if (ruList.includes(browserLang)) {
    userLang = 'ru'
  }
  return {
    lang: userLang,
    locales: { ru, en, ar, de, fr, tr, es, pt}
    // locales: { ru, en, tr, fr, de, ar }
  }
}

export default new Vuex.Store({
  namespaced: true,
  state: initialState,
  getters: {
    getLang: state => state.lang
  },
  mutations: {
    setLang(state, lang) {
      localStorage.lang = lang
      state.lang = lang
    }
  },
  actions: {
    setLang({ commit }, lang) {
      commit('setLang', lang)
    }
  }
})

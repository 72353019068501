<template>
  <div class="attention-block">
    <component
      v-if="title"
      :is="h1 ? 'h1' : 'h2'"
      class="page-title page-title_1"
      v-html="title"
    />

    <div v-if="text" class="attention-block__text" v-html="text" />

    <a
      :href="
        $route.name === 'advertisers'
          ? 'https://help.costaction.com/'
          : 'https://cabinet.costaction.com/auth/register'
      "
      class="btn btn_red attention-block__btn"
    >
      {{ $getTranslate('start_earning') }}
      <img src="@/assets/img/icons/arrow-btn.svg" class="btn__icon" alt="" />
    </a>
  </div>
</template>

<script>
export default {
  name: 'AttentionBlock',

  props: {
    title: {
      type: String,
      default: null
    },
    text: {
      type: String,
      default: null
    },

    h1: {
      type: Boolean,
      default: false
    }
  }
}
</script>

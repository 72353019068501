<template>
  <div>
    <div class="top-block__wrapper">
      <div class="top-block top-block_main">
        <AttentionBlock
          h1
          :title="$getTranslate(topBlock.title)"
          :text="$getTranslate(topBlock.text)"
          class="attention-block_main"
        />
        <div class="top-block__img top-block__img_main">
          <img src="@/assets/img/bg/bg-1.png" alt="" />
        </div>
      </div>
    </div>

    <div class="color-blocks">
      <ColorBlock :item="colorBlockHome1" />
      <ColorBlock :item="colorBlockHome2" />
    </div>

    <HowBlock :items="HowBlockItems" />

    <OffersBlock />
    <PaymentBlock />

    <div class="color-blocks">
      <ColorBlock :item="colorBlockHome3" />
      <ColorBlock :item="colorBlockHome4" />
    </div>

    <!-- <ProtectionBlock /> -->
    <PropsBlock />
  </div>
</template>

<script>
import HowBlock from '@/components/HowBlock.vue'
import OffersBlock from '@/components/OffersBlock.vue'
import PropsBlock from '@/components/PropsBlock.vue'
import PaymentBlock from '@/components/PaymentBlock.vue'
// import ProtectionBlock from '@/components/ProtectionBlock.vue'

export default {
  name: 'Home',
  components: {
    HowBlock,
    OffersBlock,
    PropsBlock,
    PaymentBlock
    // ProtectionBlock
  },

  data() {
    return {
      topBlock: {
        title: 'top_title',
        text: 'top_text'
      },

      colorBlockHome1: {
        bg: 'circles',
        title: 'color_block_title_1',
        text: 'color_block_text_1'
      },

      colorBlockHome2: {
        red: true,
        bg: 'squares',
        title: 'color_block_title_2',
        text: 'color_block_text_2'
      },

      colorBlockHome3: {
        red: true,
        bg: 'infinity',
        smallPadding: true,
        title: 'color_block_title_3',
        text: 'color_block_text_3'
      },

      colorBlockHome4: {
        smallPadding: true,
        bg: 'cloud',
        title: 'color_block_title_4',
        text: 'color_block_text_4'
      },

      HowBlockItems: [
        {
          title: 'how_block_title_1',
          text: 'how_block_text_1',
          img: 'how-1.svg'
        },
        {
          title: 'how_block_title_2',
          text: 'how_block_text_2',
          img: 'how-2.svg'
        },
        {
          title: 'how_block_title_3',
          text: 'how_block_text_3',
          img: 'how-3.svg'
        }
      ]
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/sass/main';
</style>

<template>
  <div class="payment__wrapper oblique oblique_3">
    <div class="container">
      <div class="payment__block">
        <div class="payment">
          <inline-svg
            v-for="(logo, i) in logos"
            :src="require('@/assets/img/' + logo + '.svg')"
            :key="'logo' + i"
            class="payment__logo"
          />
        </div>
        <div class="payment__desc">
          <h2 class="page-title page-title_1">
            {{ $getTranslate('payment_title') }}
          </h2>

          <div class="payment__desc-text">
            {{ $getTranslate('payment_text') }}
          </div>

          <div class="payment__list-item">
            <inline-svg
              :src="require('@/assets/img/icons/card.svg')"
              class="payment__list-item-icon"
            />
            <div
              class="payment__list-item-text"
              v-html="$getTranslate('payment_sum')"
            />
          </div>

          <div class="payment__list-item">
            <inline-svg
              :src="require('@/assets/img/icons/calendar.svg')"
              class="payment__list-item-icon"
            />
            <div
              class="payment__list-item-text"
              v-html="$getTranslate('payment_calendar')"
            />
          </div>

          <div class="payment__info">
            <inline-svg
              :src="require('@/assets/img/icons/info.svg')"
              class="payment__info-icon"
            />
            <div class="payment__info-text">
              {{ $getTranslate('payment_info') }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PaymentBlock',

  data() {
    return {
      logos: [
        'Mastercard',
        'Visa',
        'Paypal',
        'Webmoney',
        'Bitcoin',
        'Payoneer',
        'Litecoin',
        'Ripple',
        'QIWI',
        'Advcash',
        'Perfectmoney',
        'Yoomoney',
        'Tether',
        'Dogecoin',
        'Skrill'
      ]
    }
  }
}
</script>

import Vue from 'vue'
import App from './App.vue'
import InlineSvg from 'vue-inline-svg'
import router from './router'
import vClickOutside from 'v-click-outside'
import VueMeta from 'vue-meta'
import store from './store'
import VueCookies from 'vue-cookies'

import ColorBlock from '@/components/ColorBlock.vue'
import AttentionBlock from '@/components/AttentionBlock.vue'

Vue.component('InlineSvg', InlineSvg)
Vue.component('ColorBlock', ColorBlock)
Vue.component('AttentionBlock', AttentionBlock)

Vue.use(vClickOutside)
Vue.use(VueMeta)
Vue.use(VueCookies)

Vue.config.productionTip = false

const getTranslate = function getTranslate(opt) {
  if (opt in store.state.locales[store.state.lang]) {
    return store.state.locales[store.state.lang][opt]
  } else {
    return store.state.locales.ru[opt]
  }
}

Vue.prototype.$getTranslate = getTranslate

new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')

<template>
  <div
    :class="[
      'color-block_' + item.bg,
      {
        'color-block_red': item.red,
        'color-block_v2': item.smallPadding
      }
    ]"
    class="color-block"
  >
    <div class="color-block__inner">
      <div
        v-if="item.title"
        class="page-title page-title_2"
        v-html="$getTranslate(item.title)"
      />
      <div v-if="item.text" class="color-block__text" v-html="$getTranslate(item.text)" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ColorBlock',
  props: {
    item: {
      type: Object,
      default: null,
      required: true
    }
  }
}
</script>

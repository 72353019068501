<template>
  <section v-if="items" class="container">
    <div class="how-block__wrapper">
      <h2 class="page-title page-title_1">
        {{ $getTranslate('how_it_works') }}
      </h2>

      <div class="how-block">
        <HowBlockItem
          v-for="(item, i) in items"
          :item="item"
          :key="'how-' + i"
        />
      </div>
    </div>
  </section>
</template>

<script>
import HowBlockItem from '@/components/HowBlockItem.vue'

export default {
  name: 'HowBlock',
  components: {
    HowBlockItem
  },

  props: {
    items: {
      type: Array,
      default: null
    }
  }
}
</script>
